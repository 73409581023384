import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';

import styles from './styles.module.scss';

import { TagSearchCondition } from '../TagSearchCondition';
import { DISABLED_TEXT } from '../const';
import { TagPresenterProps } from '../types';

export const TagPresenter: React.FC<TagPresenterProps> = (props) => (
  <ModalDialog
    isShown={props.isShown}
    size="wide"
    title="タグ"
    okButton={{
      children: '保存する',
      onClick: props.form.onSubmit,
      isDisabled: props.form.isDisabled,
    }}
    cancelButton={{
      children: '閉じる',
      onClick: props.onClose,
      color: 'basic',
    }}
    subActionButton={
      props.form.isDisabled
        ? undefined
        : {
            children: props.form.tags.isAllSelected
              ? props.form.tags.allClear.label
              : props.form.tags.allSelect.label,
            onClick: props.form.tags.isAllSelected
              ? props.form.tags.allClear.onClick
              : props.form.tags.allSelect.onClick,
          }
    }
  >
    {props.form.isDisabled ? (
      DISABLED_TEXT
    ) : (
      <form>
        <div className={styles.checkboxesWrapper}>
          <CheckboxList
            options={props.tagOptions.map((option) => ({
              label: `#${option.name}`,
              value: String(option.id),
            }))}
            gap={{ row: 16, column: 16 }}
            {...props.form.tags}
          />
        </div>
        <div className={styles.divider}>
          <Divider />
        </div>
        <TagSearchCondition {...props.form} />
      </form>
    )}
  </ModalDialog>
);
