import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';

import styles from './styles.module.scss';

import React from 'react';

import { TagSearchCondition } from '../TagSearchCondition';
import { DISABLED_TEXT } from '../const';
import { TagGroupPresenterProps } from '../types';

export const TagGroupPresenter: React.FC<TagGroupPresenterProps> = (props) => (
  <ModalDialog
    isShown={props.isShown}
    size="wide"
    title="タグ"
    okButton={{
      children: '保存する',
      onClick: props.form.onSubmit,
      isDisabled: props.form.isDisabled,
    }}
    cancelButton={{
      children: '閉じる',
      onClick: props.onClose,
      color: 'basic',
    }}
  >
    {props.form.isDisabled ? (
      DISABLED_TEXT
    ) : (
      <form>
        <div className={styles.tagGroups}>
          {props.tagOptionGroups.map((group, index) => {
            if (group.tags.length === 0) return null;

            const optionIds = group.tags.map((option) => option.id);
            const isAllSelected =
              props.form.tags.currentSelectedOptions &&
              optionIds.every((optionsKey) =>
                props.form.tags.currentSelectedOptions.includes(optionsKey),
              );

            return (
              <React.Fragment key={`${String(group.id)}-${index}`}>
                <div className={styles.tagGroup}>
                  <div className={styles.tagGroupHeader}>
                    <h3 className={styles.tagGroupGroup}>
                      {group.name ?? '（グループ未設定）'}
                    </h3>
                    <Button
                      color="primary"
                      submitType="button"
                      onClick={() => {
                        props.form.tags.groupToggle(isAllSelected, optionIds);
                      }}
                      type="skeleton"
                    >
                      {isAllSelected ? '全てを解除' : '全てを選択'}
                    </Button>
                  </div>
                  <div className={styles.checkboxesWrapper}>
                    <CheckboxList
                      options={group.tags.map((o) => ({
                        label: o.name,
                        value: o.id,
                      }))}
                      gap={{ row: 16, column: 16 }}
                      {...props.form.tags}
                    />
                  </div>
                </div>
                {index < props.tagOptionGroups.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.divider}>
          <Divider />
        </div>
        <TagSearchCondition {...props.form} />
      </form>
    )}
  </ModalDialog>
);
