// 医療機関ラベルマスタから、フォームの初期値を作成する
// やむを得ず eslint-disable する

import {
  LabelSearchCondition,
  MedicalInstitutionLabelComponentType,
} from '@/src/api/generated';
import { MedicalInstitutionLabelFilterConditionSchema } from '@/src/types/medicalInstitutionLabel';

import pickBy from 'lodash.pickby';

/**
 * 旧形式の入力から新形式の入力に変換する
 */
export const toFilterConditionSchema = (
  c: LabelSearchCondition,
  componentType: MedicalInstitutionLabelComponentType,
): MedicalInstitutionLabelFilterConditionSchema => ({
  labelId: c.labelId.toString(),
  // null, undefined なキーを除外するためのpickBy
  ...pickBy({
    textCondition: toFilterConditionSchemaTextPart(c.textConditions),
    integerCondition: toFilterConditionSchemaIntegerPart(c.integerConditions),
    selectionCondition: toFilterConditionSchemaSelectionPart(c, componentType),
  }),
});
const toFilterConditionSchemaTextPart = (
  c: LabelSearchCondition['textConditions'],
): MedicalInstitutionLabelFilterConditionSchema['textCondition'] =>
  c && c.contains !== undefined ? { value: c.contains } : undefined;
const toFilterConditionSchemaIntegerPart = (
  c: LabelSearchCondition['integerConditions'],
): MedicalInstitutionLabelFilterConditionSchema['integerCondition'] =>
  c && c.moreEqual !== null && c.moreEqual !== undefined
    ? {
        value: c.moreEqual,
        conditionType: 'moreEqual',
      }
    : c && c?.lessEqual !== null && c.lessEqual !== undefined
    ? {
        value: c.lessEqual,
        conditionType: 'lessEqual',
      }
    : undefined;
const toFilterConditionSchemaSelectionPart = (
  labelSearchCondition: LabelSearchCondition,
  componentType: MedicalInstitutionLabelComponentType,
): MedicalInstitutionLabelFilterConditionSchema['selectionCondition'] => {
  if (componentType === MedicalInstitutionLabelComponentType.Checkbox) {
    const c = labelSearchCondition.selectionConditions;
    return c?.haveAny
      ? {
          ids: c.haveAny.map(String),
          conditionType: 'haveAny',
        }
      : c?.haveAll
      ? {
          ids: c.haveAll.map(String),
          conditionType: 'haveAll',
        }
      : c?.notHaveAll
      ? {
          ids: c.notHaveAll.map(String),
          conditionType: 'notHaveAll',
        }
      : undefined;
  } else if (componentType === MedicalInstitutionLabelComponentType.Area) {
    const c = labelSearchCondition.areaConditions;
    return c?.haveAny
      ? {
          ids: c.haveAny.map(String),
          conditionType: 'in',
        }
      : c?.notHaveAll
      ? {
          ids: c.notHaveAll.map(String),
          conditionType: 'notIn',
        }
      : undefined;
  } else {
    const c = labelSearchCondition.selectionConditions;
    return c?.haveAny
      ? {
          ids: c.haveAny.map(String),
          conditionType: 'in',
        }
      : c?.notHaveAll
      ? {
          ids: c.notHaveAll.map(String),
          conditionType: 'notIn',
        }
      : undefined;
  }
};

/**
 * 新形式の出力から旧形式の出力に変換する
 */
export const toSearchCondition = (
  c: MedicalInstitutionLabelFilterConditionSchema,
  componentType: MedicalInstitutionLabelComponentType,
): LabelSearchCondition => ({
  labelId: Number(c.labelId),
  // null, undefined なキーを除外するためのpickBy
  ...pickBy({
    textConditions: toSearchConditionTextPart(c.textCondition),
    integerConditions: toSearchConditionIntegerPart(c.integerCondition),
    ...toSearchConditionSelection(c.selectionCondition, componentType),
  }),
});
const toSearchConditionTextPart = (
  c: MedicalInstitutionLabelFilterConditionSchema['textCondition'],
): LabelSearchCondition['textConditions'] =>
  c && c.value !== undefined ? { contains: c.value } : undefined;
const toSearchConditionIntegerPart = (
  c: MedicalInstitutionLabelFilterConditionSchema['integerCondition'],
): LabelSearchCondition['integerConditions'] => {
  if (!c || c.value === undefined || c.value === null) return undefined;
  switch (c.conditionType) {
    case 'moreEqual':
      return { moreEqual: c.value };
    case 'lessEqual':
      return { lessEqual: c.value };
    default:
      assertIsNever(c.conditionType);
  }
};
const toSearchConditionSelection = (
  c: MedicalInstitutionLabelFilterConditionSchema['selectionCondition'],
  componentType: MedicalInstitutionLabelComponentType,
): Pick<LabelSearchCondition, 'selectionConditions' | 'areaConditions'> => {
  if (!c || c.ids.length === 0) return {};

  const conditions = () => {
    switch (c.conditionType) {
      case 'in':
        return { haveAny: c.ids.map(Number) };
      case 'haveAny':
        return { haveAny: c.ids.map(Number) };
      case 'haveAll':
        return { haveAll: c.ids.map(Number) };
      case 'notHaveAll':
      case 'notIn':
        return { notHaveAll: c.ids.map(Number) };
      default:
        assertIsNever(c.conditionType);
    }
  };

  return componentType === MedicalInstitutionLabelComponentType.Area
    ? { areaConditions: conditions() }
    : { selectionConditions: conditions() };
};
