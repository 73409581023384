import {
  LabelSearchCondition,
  MedicalInstitutionLabelComponentType,
  GetMedicalInstitutionLabelsResponse,
} from '@/src/api/generated';
import { useGetMedicalInstitutionLabels } from '@/src/hooks/fetcher/useGetMedicalInstitutionLabels';

import { toFilterConditionSchema, toSearchCondition } from './mapper';

import { ComponentProps } from 'react';

import { MedicalInstitutionLabelFilterChip } from '../MedicalInstitutionLabelFilterChip';

type Props = Omit<
  ComponentProps<typeof MedicalInstitutionLabelFilterChip>,
  'labelFilterConditions' | 'onSubmit'
> & {
  searchConditions: LabelSearchCondition[];
  onSubmit: (searchConditions: LabelSearchCondition[]) => void;
};

/**
 * 医療機関ラベル検索APIパラメーターでの入出力形式のアダプターを介して扱うためのラッパーコンポーネント。
 * 既存コンポーネントとの互換性を保つために用意している。
 */
export const MedicalInstitutionLabelFilterChipUsingSearchFormat: React.FC<
  Props
> = (props) => {
  const labelsResponse = useGetMedicalInstitutionLabels();

  // データを未取得な場合でもチップ自体は表示する
  if (!labelsResponse.data)
    return (
      <MedicalInstitutionLabelFilterChip
        {...props}
        labelFilterConditions={[]}
        onSubmit={() => {
          /* no-op */
        }}
      />
    );

  return (
    <MedicalInstitutionLabelFilterChipUsingSearchFormatContainer
      {...props}
      labelsResponse={labelsResponse.data}
    />
  );
};

const MedicalInstitutionLabelFilterChipUsingSearchFormatContainer: React.FC<
  Props & {
    labelsResponse: GetMedicalInstitutionLabelsResponse;
  }
> = (props) => {
  const labelIdComponentTypeMap = Object.fromEntries(
    props.labelsResponse.labels.map(
      (label): [string, MedicalInstitutionLabelComponentType] => [
        label.id.toString(),
        label.componentType,
      ],
    ),
  );

  return (
    <MedicalInstitutionLabelFilterChip
      {...props}
      labelFilterConditions={props.searchConditions.flatMap((c) => {
        const componentType = labelIdComponentTypeMap[c.labelId.toString()];

        return componentType ? toFilterConditionSchema(c, componentType) : [];
      })}
      onSubmit={(labelFilterConditions) => {
        props.onSubmit(
          labelFilterConditions.map((labelFilterCondition) =>
            toSearchCondition(
              labelFilterCondition,
              labelIdComponentTypeMap[labelFilterCondition.labelId],
            ),
          ),
        );
      }}
    />
  );
};
