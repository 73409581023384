import { Tag } from '@/src/components/features/UtilModal/TagFilterModal/Tag';
import { TagGroup } from '@/src/components/features/UtilModal/TagFilterModal/TagGroup';

import { TagAndTagGroupProps } from './types';

type Props = TagAndTagGroupProps & {
  isTagGroup: boolean;
};

export const TagFilterModalPresenter: React.FC<Props> = (props) => {
  return props.isTagGroup ? <TagGroup {...props} /> : <Tag {...props} />;
};
