import { tagsApi } from '@/src/api';
import { IdsSearchCondition } from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';

import { TagFilterModalPresenter } from './presenter';
import { ModalInputMap } from './types';

type Props = Pick<
  React.ComponentProps<typeof TagFilterModalPresenter>,
  'isShown' | 'onClose'
> & {
  selectedTagIds: string[];
  selectedTagSearchCondition: IdsSearchCondition;
  onSubmit: (tagFields: {
    tagIds: string[];
    tagSearchCondition: IdsSearchCondition;
  }) => void;
};

export const TagFilterModal: React.FC<Props> = (props) => {
  const formData = useFormWrapper<ModalInputMap>({
    mode: 'all',
    defaultValues: {
      tags: props.selectedTagIds,
      tagSearchCondition: props.selectedTagSearchCondition,
    },
  });

  const tagGroupsResponse = useAsyncFetch(
    apiKey('tagsApi', 'getTagGroups'),
    () => tagsApi.getTagGroups(),
  );

  const throwFetchError = useFetchError();

  if (tagGroupsResponse.error != null) {
    throwFetchError(tagGroupsResponse.error);
  }

  return tagGroupsResponse.data?.tagGroups ? (
    <TagFilterModalPresenter
      {...props}
      isDisabled={
        tagGroupsResponse.data.tagGroups.length === 0 ||
        tagGroupsResponse.data.tagGroups.every(
          (group) => group.tags.length === 0,
        )
      }
      isTagGroup={tagGroupsResponse.data.tagGroups.some(
        (tag) => tag.id !== null,
      )}
      tagOptionGroups={tagGroupsResponse.data.tagGroups}
      formData={formData}
    />
  ) : null;
};
