import { TagGroupPresenter } from './presenter';

import { TagAndTagGroupProps } from '../types';

export const TagGroup: React.FC<TagAndTagGroupProps> = (props) => {
  const inputMap = props.formData.watch();

  return (
    <TagGroupPresenter
      {...props}
      form={{
        onSubmit: props.formData.handleSubmit((data) => {
          props.onSubmit({
            tagIds: data.tags,
            tagSearchCondition: data.tagSearchCondition,
          });
        }),
        isDisabled: props.isDisabled,
        tags: {
          ...props.formData.register('tags'),
          errorMessages: [],
          currentSelectedOptions: inputMap.tags,
          groupToggle: (isAllSelected, optionsKeys) =>
            props.formData.setValue('tags', [
              ...(isAllSelected ? [] : optionsKeys),
              ...inputMap.tags.filter((selectedTag) => {
                return !optionsKeys.includes(selectedTag);
              }),
            ]),
        },
        tagSearchCondition: {
          ...props.formData.register('tagSearchCondition'),
          errorMessages: [],
        },
      }}
      tagOptionGroups={props.tagOptionGroups}
    />
  );
};
