import styles from './styles.module.scss';

type Props = {
  messages: (string | undefined)[];
};

export const ErrorMessages: React.FC<Props> = (props) => (
  <>
    {props.messages
      .filter((message) => message)
      .map((message) => (
        <p key={message} className={styles.base}>
          {message}
        </p>
      ))}
  </>
);
