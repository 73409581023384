import { Department } from '@/src/api/generated';
import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';
import { GroupToggleAvailableFormProps } from '@/src/types/form';

import styles from './styles.module.scss';

import React from 'react';

export type ModalInputMap = {
  departmentIds: string[];
};

type Props = {
  form: Omit<
    GroupToggleAvailableFormProps<ModalInputMap, 'departmentIds'>,
    'isDisabled'
  >;
  isShown: boolean;
  onClose: VoidFunction;
  departments: Department[];
};

export const DepartmentFilterModalPresenter: React.FC<Props> = (props) => (
  <ModalDialog
    isShown={props.isShown}
    size="wide"
    title="標榜診療科"
    okButton={{
      children: '保存する',
      onClick: props.form.onSubmit,
    }}
    cancelButton={{
      children: '閉じる',
      onClick: props.onClose,
      color: 'basic',
    }}
  >
    <form>
      <div className={styles.departments}>
        {props.departments.map((department, index) => {
          const optionsKeys = department.options.map((option) => option.key);
          const isAllSelected =
            props.form.departmentIds.currentSelectedOptions &&
            optionsKeys.every((optionsKey) =>
              props.form.departmentIds.currentSelectedOptions.includes(
                optionsKey,
              ),
            );

          return (
            <React.Fragment key={`${department.key}-${index}`}>
              <div className={styles.department}>
                <div className={styles.departmentHeader}>
                  <h3 className={styles.departmentGroup}>{department.value}</h3>
                  <Button
                    color="primary"
                    submitType="button"
                    onClick={() => {
                      props.form.departmentIds.groupToggle(
                        isAllSelected,
                        optionsKeys,
                      );
                    }}
                    type="skeleton"
                  >
                    {isAllSelected ? '全てを解除' : '全てを選択'}
                  </Button>
                </div>
                <div className={styles.checkboxesWrapper}>
                  <CheckboxList
                    options={department.options.map((o) => ({
                      label: o.value,
                      value: o.key,
                    }))}
                    gap={{ row: 16, column: 16 }}
                    {...props.form.departmentIds}
                  />
                </div>
              </div>
              {index < props.departments.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </div>
    </form>
  </ModalDialog>
);
