import { medicalInstitutionsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';

import { DepartmentFilterModalPresenter, ModalInputMap } from './presenter';

type Props = Pick<
  React.ComponentProps<typeof DepartmentFilterModalPresenter>,
  'isShown' | 'onClose'
> & {
  selectedDepartmentIds: string[];
  onSubmit: (selectedDepartmentIds: string[]) => void;
};

export const DepartmentFilterModal: React.FC<Props> = (props) => {
  const departmentsResponse = useAsyncFetch(
    apiKey('medicalInstitutionsApi', 'getDepartments'),
    () => medicalInstitutionsApi.getDepartments(),
  );
  const throwFetchError = useFetchError();

  const form = useFormWrapper<ModalInputMap>({
    defaultValues: {
      departmentIds: props.selectedDepartmentIds,
    },
  });

  if (departmentsResponse.error) {
    throwFetchError(departmentsResponse.error);
  }

  const inputMap = form.watch();

  return departmentsResponse.data?.departments ? (
    <DepartmentFilterModalPresenter
      isShown={props.isShown}
      onClose={props.onClose}
      departments={departmentsResponse.data?.departments}
      form={{
        onSubmit: form.handleSubmit((data) => {
          props.onSubmit(data.departmentIds);
        }),
        departmentIds: {
          ...form.register('departmentIds'),
          errorMessages: [],
          currentSelectedOptions: inputMap.departmentIds,
          groupToggle: (isAllSelected, optionsKeys) =>
            form.setValue('departmentIds', [
              ...(isAllSelected ? [] : optionsKeys),
              ...inputMap.departmentIds.filter((selectedDepartment) => {
                return !optionsKeys.includes(selectedDepartment);
              }),
            ]),
        },
      }}
    />
  ) : null;
};
