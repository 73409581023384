import { useModal } from '@/src/hooks/useModal';

import { DepartmentFilterChipPresenter } from './presenter';

type Props = Omit<
  React.ComponentProps<typeof DepartmentFilterChipPresenter>,
  'modal'
>;

export const DepartmentFilterChip: React.FC<Props> = (props) => {
  const modal = useModal();

  return <DepartmentFilterChipPresenter {...props} modal={modal} />;
};
