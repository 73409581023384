import { DepartmentFilterModal } from '@/src/components/features/UtilModal/DepartmentFilterModal';
import { Chip } from '@/src/components/foundations/Buttons/Chip';
import { useModal } from '@/src/hooks/useModal';

type Props = {
  selectedDepartmentIds: string[];
  modal: ReturnType<typeof useModal>;
  onSubmit: React.ComponentProps<typeof DepartmentFilterModal>['onSubmit'];
  size: 'small' | 'medium';
};

export const DepartmentFilterChipPresenter: React.FC<Props> = (props) => (
  <>
    <Chip
      size={props.size}
      status={props.selectedDepartmentIds.length > 0 ? 'active' : 'normal'}
      onClick={props.modal.show}
    >
      {props.selectedDepartmentIds.length > 0
        ? `標榜診療科：${props.selectedDepartmentIds.length}`
        : '標榜診療科'}
    </Chip>
    {props.modal.isShown && (
      <DepartmentFilterModal
        isShown={props.modal.isShown}
        onClose={props.modal.hide}
        selectedDepartmentIds={props.selectedDepartmentIds}
        onSubmit={(values) => {
          props.modal.hide();
          props.onSubmit(values);
        }}
      />
    )}
  </>
);
