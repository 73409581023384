import { TagPresenter } from './presenter';

import { TagAndTagGroupProps } from '../types';

export const Tag: React.FC<TagAndTagGroupProps> = (props) => {
  const inputMap = props.formData.watch();

  const tagOptions = props.tagOptionGroups.flatMap((group) => group.tags);

  return (
    <TagPresenter
      {...props}
      form={{
        onSubmit: props.formData.handleSubmit((data) => {
          props.onSubmit({
            tagIds: data.tags,
            tagSearchCondition: data.tagSearchCondition,
          });
        }),
        isDisabled: props.isDisabled,
        tags: {
          ...props.formData.register('tags'),
          errorMessages: [],
          allSelect: {
            label: '全てを選択',
            onClick: () => {
              props.formData.setValue(
                'tags',
                tagOptions.map((option) => String(option.id)),
              );
            },
          },
          allClear: {
            label: '全てを解除',
            onClick: () => props.formData.setValue('tags', []),
          },
          isAllSelected: inputMap.tags.length === tagOptions.length,
        },
        tagSearchCondition: {
          ...props.formData.register('tagSearchCondition'),
          errorMessages: [],
        },
      }}
      tagOptions={tagOptions}
    />
  );
};
