import { IdsSearchCondition } from '@/src/api/generated';
import { TagFilterModal } from '@/src/components/features/UtilModal/TagFilterModal';
import { Chip } from '@/src/components/foundations/Buttons/Chip';
import { useModal } from '@/src/hooks/useModal';

type Props = {
  selectedTagIds: string[];
  selectedTagSearchCondition: IdsSearchCondition;
  size: 'small' | 'medium';
  onSubmit: React.ComponentProps<typeof TagFilterModal>['onSubmit'];
};

export const TagFilterChipPresenter: React.FC<Props> = (props) => {
  const tagFilterModal = useModal();

  return (
    <>
      <Chip
        size={props.size}
        status={props.selectedTagIds.length > 0 ? 'active' : 'normal'}
        onClick={tagFilterModal.show}
      >
        {props.selectedTagIds.length > 0
          ? `タグ：${props.selectedTagIds.length}`
          : 'タグ'}
      </Chip>
      {tagFilterModal.isShown && (
        <TagFilterModal
          isShown={tagFilterModal.isShown}
          onClose={tagFilterModal.hide}
          selectedTagIds={props.selectedTagIds}
          selectedTagSearchCondition={props.selectedTagSearchCondition}
          onSubmit={(values) => {
            tagFilterModal.hide();
            props.onSubmit(values);
          }}
        />
      )}
    </>
  );
};
