import { RadioGroup } from '@/src/components/foundations/Forms/RadioGroup';

import styles from './styles.module.scss';

import { TagSearchConditionProps } from '../types';

export const TagSearchConditionPresenter: React.FC<TagSearchConditionProps> = (
  props,
) => (
  <div className={styles.base}>
    <h3 className={styles.title}>絞り込み条件</h3>
    <div className={styles.content}>
      <RadioGroup
        {...props.tagSearchCondition}
        radios={[
          {
            label: 'いずれかのタグを含む（OR検索）',
            value: 'or',
          },
          {
            label: 'すべてのタグを含む（AND検索）',
            value: 'and',
          },
          {
            label: 'すべてのタグを含まない（NOT検索）',
            value: 'nor',
          },
        ]}
        distance={16}
        orientation="horizontal"
      />
    </div>
  </div>
);
