import styles from './styles.module.scss';

import { CSSProperties, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

type Props = {
  children: React.ReactNode;
  isExpanded: boolean;
  width?: CSSProperties['width'];
  heightOnFold?: number;
};

export const Accordion: React.FC<Props> = (props) => {
  const nodeRef = useRef(null);

  // appearでの初期状態指定がうまく効かないため、初期状態に従ってのスタイルを適用をstateを利用して行う
  // unmountOnExitでDOM要素が削除される場合はスタイル指定が邪魔になるので、heightOnFoldが指定されている場合のみ適用する
  const [isExpandedOnMounted] = useState(props.isExpanded);
  const initialStyles = !props.heightOnFold
    ? undefined
    : { gridTemplateRows: isExpandedOnMounted ? '1fr' : '0fr' };

  return (
    <CSSTransition
      in={props.isExpanded}
      nodeRef={nodeRef}
      classNames={{ ...styles }}
      timeout={250}
      unmountOnExit={!props.heightOnFold}
    >
      <div
        className={styles.contentsDetailWrapper}
        ref={nodeRef}
        style={{
          width: props.width,
          ...initialStyles,
        }}
      >
        <div style={{ minHeight: props.heightOnFold ?? 0 }}>
          {props.children}
        </div>
      </div>
    </CSSTransition>
  );
};
